<template>
  <!-- 角色管理页面 -->
  <div class="box" id="role_manage">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left">
          <a-button icon="plus" type="primary" @click="addRole" v-show="isShowAdd">新增</a-button>
        </div>
        <div class="top_rigiht">
          <a-input ref="userNameInput" v-model="searchVal" placeholder="请输入角色名称" style="width: 200px" @pressEnter="searchClick">
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button icon="search" type="primary" @click="searchClick">搜索</a-button>
        </div>
      </div>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" :row-key="record => record.id" :pagination="false" :loading="tableLoading" bordered>
        <span slot="operation" slot-scope="operation, record, index">
          <a v-show="isShowEdit" class="margin_right20" @click="editClick(record)">编辑</a>
          <a v-show="isShowDelete" @click="del(record.roleNo,index)">删除</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <div class="page">
          <a-pagination :show-total="total => `总共 ${count} 条`" v-model="pageNo" :total="count" @change="showSizeChangeFn" show-less-items  v-show="tableData.length > 0" />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import { RoleListApi, RoleDeleteApi } from "@/request/api/rolePower";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    this.roleListFn();
    if (codeFn("/admin/sys/role/add")) this.isShowAdd = true;
    if (codeFn("/admin/sys/role/detail/{id}")) this.isShowEdit = true;
    if (codeFn("/admin/sys/role/delete/{id}")) this.isShowDelete = true;
  },
    watch: {
        $route (to) {
            if (to.path === "/rolePower/roleManage") {
                this.roleListFn();
            }
        },
    },
  data() {
    return {
      pageNo: 1,
      count: 200,
      tableLoading: true, // 表格是否是加载中
      searchVal: "", // 搜索框的值
      columns: [
        {
          title: "角色",
          dataIndex: "roleName",
          key: "roleName",
          width: "33.3%",
          scopedSlots: { customRender: "roleName" },
        },
        {
          title: "描述",
          dataIndex: "remark",
          key: "remark",
          width: "33.3%",
          ellipsis: true, // 超过宽度自动省略
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: "33.3%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isShowModal: true, // 模态框是否显示隐藏
      isShowAdd: false, // 是否显示新增的按钮
      isShowEdit: false, // 是否显示编辑的按钮
      isShowDelete: false, // 是否显示删除的按钮
    };
  },
  methods: {
    // 添加角色
    addRole() {
      this.$router.push(`/rolePower/roleManage/addRole/88`); // 跳转到添加角色页面
    },
    // 编辑的按钮
    editClick(record) {
      this.$router.push(`/rolePower/roleManage/addRole/${record.roleNo}`);
    },
    // 删除的按钮
    del(id,index) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复, 是否确认删除",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          RoleDeleteApi({ roleNo: id }).then(({ code }) => {
            if (code == 200) {
              message.success("删除成功", 3);
              that.tableData.splice(index, 1)
            }
          });
        },
      });
    },
    // 按搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.roleListFn();
    },
    // 分页功能切换的回调
	  showSizeChangeFn(current, pageSize) {
		this.pageNo = current;
		this.pageSize = pageSize;
		this.roleListFn();
    },
    // 封装 角色列表请求
    roleListFn() {
      RoleListApi({
        page: this.pageNo,
        name: this.searchVal, // 搜索关键字-角色名称
      }).then(({ code, data }) => {
        if (code == 200) {
          	this.tableLoading = false;
			this.tableData = data.list;
            this.count = data.count
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#role_manage");
    },
  },
};
</script>
 
<style lang="less" scoped>
#role_manage {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.box {
  position: relative;
  padding: 24px;
  background-color: #fff;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    .ant-btn {
      margin-left: 10px;
    }
  }
}
// 表格
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
// 表头字体变粗
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
.page{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 10px;
}
</style>